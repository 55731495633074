import { FC } from 'react';
import '../../assets/styles/_burger.scss';
import classNames from 'classnames';

export const Burger: FC<{ openMenu: boolean; setPage: (value: string) => void }> = ({
	openMenu,
	setPage,
}) => {
	return (
		<ul
			className={classNames('menu', {
				show: openMenu,
			})}
		>
			<li onClick={() => setPage('')} className="menu-item visible">
				<a className="menu-link" href="#start">
					Home
				</a>
			</li>
			<li onClick={() => setPage('')} className="menu-item visible flex-end">
				<a className="menu-link" href="#ello">
					About the company
				</a>
			</li>
			<li onClick={() => setPage('')} className="menu-item visible">
				<a className="menu-link" href="#Placing">
					Placing your video
				</a>
			</li>
			<li className="menu-item visible flex-end">
				<a
					href="https://youtube.com/@ELLO?si=5bQ_O5eUSVDYe05U"
					className="navigation__link"
					target="_blank"
					rel="noreferrer"
				>
					ELLO Video Production
				</a>
			</li>
			<li className="menu-item visible">
				<a className="menu-link" href="#Partnership">
					Partnership
				</a>
			</li>
			<li className="menu-item visible flex-end">
				<a
					href="https://docs.google.com/forms/d/e/1FAIpQLSfdmbGE8zjgGaZy-vY_KacDU9hsRx2wjR1LiJ5zp3typtwtHQ/viewform?usp=sf_link"
					className="navigation__link"
					target="_blank"
					rel="noreferrer"
				>
					Contact
				</a>
			</li>
			<li className="menu-item">
				<a
					target="_blank"
					rel="noreferrer"
					href="https://youtube.com/@ELLO?si=5bQ_O5eUSVDYe05U"
					className="nav-left__link"
				>
					YouTube
				</a>
			</li>
			<li className="menu-item flex-end">
				<a id="contact" className="menu-link" href="/contact">
					Facebook
				</a>
			</li>
			<li className="menu-item">
				<a id="contact" className="menu-link" href="/contact">
					Twitter
				</a>
			</li>
			<li className="menu-item flex-end">
				<a id="contact" className="menu-link" href="/contact">
					Instagram
				</a>
			</li>
		</ul>
	);
};
