import { FC } from 'react';
import { BigSlider } from '../BigSlider/BigSlider';

export const FirstBlock: FC = () => {
	return (
		<section id="start" className="first-block">
			<div className="first-block__big-slider-wrap">
				<BigSlider />
				<div className="text-wrap">
					<div className="text">Hot chocolate - Burn me to the ground</div>
					<div className="subtext">Premiere</div>
					<a
						className="first-block__btn"
						href="https://youtube.com/@ELLO?si=5bQ_O5eUSVDYe05U"
						target="_blank"
						rel="noreferrer"
					>
						Watch Video
					</a>
				</div>
			</div>
		</section>
	);
};
