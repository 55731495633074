import { FC } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSwiper } from 'swiper/react';
import { ISlideNextButton } from '../../types/TSlider';

const SlideNextButton: FC<ISlideNextButton> = ({ nextImg, startImg, numberImg }) => {
	const swiper = useSwiper();

	return (
		<button
			// disabled={startImg === numberImg}
			onClick={() => {
				swiper.slideNext();
				nextImg();
			}}
			type="button"
			className="slider-arrow slider-arrow--next"
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="18"
				height="19"
				viewBox="0 0 18 19"
				fill="none"
			>
				<path
					d="M6 15.5L12.75 9.5L6 3.5"
					stroke="#0A49A5"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</button>
	);
};

export default SlideNextButton;
