import moment from 'moment';
import { FC } from 'react';

export const Footer: FC<{ setPage: (value: string) => void }> = ({ setPage }) => {
	return (
		<footer className="footer">
			<div className="footer__wrapper">
				<p className="footer__copy support__copy">{`© ${moment(new Date()).format(
					'YYYY',
				)} Ello Messenger Corporation`}</p>
				<ul className="footer__policy">
					<li onClick={() => setPage('policy')} className="footer__text">
						<div>Privacy Policy</div>
					</li>
					{/* <li className="footer__text text-right">
						<div>Cookie Policy</div>
					</li> */}
					<li onClick={() => setPage('terms')} className="footer__text">
						<div>Terms</div>
					</li>
					<li className="footer__text text-right">
						<a
							href="https://docs.google.com/forms/d/e/1FAIpQLSfdmbGE8zjgGaZy-vY_KacDU9hsRx2wjR1LiJ5zp3typtwtHQ/viewform?usp=sf_link"
							className="navigation__link"
							target="_blank"
							rel="noreferrer"
						>
							<div style={{ borderRight: 'none' }}>Contact</div>
						</a>
					</li>
					{/* <li className='footer__text'>
            <Link to={ERoutes.FAQ}>FAQ</Link>
          </li>
          <li className='footer__text text-right'>
            <Link to={ERoutes.manuals}>Manuals</Link>
          </li> */}
				</ul>
			</div>
		</footer>
	);
};
