import { FC } from 'react';

export const NavigationLeft: FC = () => {
	return (
		<ul className="nav-left__list">
			<li className="nav-left__item">
				<a
					target="_blank"
					rel="noreferrer"
					href="https://youtube.com/@ELLO?si=5bQ_O5eUSVDYe05U"
					className="nav-left__link"
				>
					YouTube
				</a>
			</li>
			<li className="nav-left__item">
				<a href="" className="nav-left__link">
					Facebook
				</a>
			</li>
			<li className="nav-left__item">
				<a href="" className="nav-left__link">
					Twitter
				</a>
			</li>
			<li className="nav-left__item">
				<a href="" className="nav-left__link">
					Instagram
				</a>
			</li>
		</ul>
	);
};
