/* eslint-disable import/no-unresolved */
import { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import photo from '../../assets/images/slide-big.png';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';

export const BigSlider: FC = () => {
	return (
		<Swiper
			breakpoints={{
				320: {
					width: 300,
				},
				545: {
					width: 500,
				},
				768: {
					width: 700,
				},
			}}
			spaceBetween={10}
			pagination={{
				clickable: true,
			}}
			modules={[Pagination]}
			// className="my-swiper"
		>
			{[1, 2, 3, 4, 5].map((el) => (
				<SwiperSlide key={el}>
					<img className="img-response" src={photo} alt="" />
				</SwiperSlide>
			))}
		</Swiper>
	);
};
