import { FC, useState } from 'react';
import { NavigationRight } from '../NavigationRight/NavigationRight';
import logo from '../../assets/images/partners.svg';
import { NavigationLeft } from '../NavigationLeft/NavigationLeft';
import { Burger } from '../Burger/Burger';
import classNames from 'classnames';

export const Header: FC<{
	onMenu: () => void;
	openMenu: boolean;
	setPage: (value: string) => void;
}> = ({ onMenu, openMenu, setPage }) => {
	const onUp = () => {
		window.scrollTo({
			top: 0,
			// behavior: 'smooth',
		});
	};

	return (
		<header className="header">
			<div className="header__right-content">
				<img
					style={{ cursor: 'pointer' }}
					onClick={onUp}
					src={logo}
					alt="logo"
					className="header__logo"
				/>
				<NavigationRight setPage={setPage} />
			</div>
			<div className="header__nav-left nav-left top-nav">
				<NavigationLeft />
				<div
					onClick={onMenu}
					id="nav-icon2"
					className={classNames('burger-btn', { open: openMenu })}
				>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
		</header>
	);
};
