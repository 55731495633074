import React, { useState } from 'react';
import { Header } from './components/Header/Header';
import { FirstBlock } from './components/FirstBlock/FirstBlock';
import { SmallSlider } from './components/SmallSlider/SmallSlider';
import { AboutCompany } from './components/AboutCompany/AboutCompany';
import { Placement } from './components/Placement/Placement';
import { Partnership } from './components/Partnership/Partnership';
import { Footer } from './components/Footer/Footer';
import { Burger } from './components/Burger/Burger';
import { Terms } from './components/Terms/Terms';
import { PrivacyPolicy } from './components/PrivacyPolicy/PrivacyPolicy';

export const App = () => {
	const [openMenu, setOpenMenu] = useState(false);
	const [page, setPage] = useState('');

	const onMenu = () => {
		setOpenMenu(!openMenu);
	};

	const definePage = () => {
		switch (page) {
			case 'terms':
				return (
					<div className="wrap">
						<div className="faq-list container container-custom container-custom-manual container-custom-terms">
							<div className="title">Terms of Service Agreement Ello Messenger Corporation</div>
							<div className="subtitle">Updated October 11, 2023</div>
						</div>
						<Terms />
					</div>
				);

			case 'policy':
				return (
					<div className="wrap">
						<div className="faq-list container container-custom container-custom-manual container-custom-terms">
							<div className="title">
								Privacy Policy <br /> Ello Messenger Corporation
							</div>
							{/* <div className="subtitle">Updated October 11, 2023</div> */}
						</div>
						<PrivacyPolicy />
					</div>
				);

			default:
				return (
					<>
						<div className="bg-first-blok">
							<div className="content">
								<FirstBlock />
							</div>
							<SmallSlider />
							<div className="big-title">Our premieres</div>
						</div>
						<div className="content">
							<AboutCompany />
						</div>
						<div className="bg-third-blok">
							<div className="content">
								<Placement />
							</div>
						</div>
						<div className="content">
							<Partnership />
						</div>
					</>
				);
		}
	};

	return (
		<div className="wrapper">
			<Burger setPage={setPage} openMenu={openMenu} />
			<Header onMenu={onMenu} openMenu={openMenu} setPage={setPage} />
			{definePage()}
			<Footer setPage={setPage} />
		</div>
	);
};
