/* eslint-disable import/no-unresolved */
import { FC, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import SliderPreviousButton from '../SliderPreviousButton/SliderPreviousButton';
import SlideNextButton from '../SlideNextButton/SlideNextButton';
import photoSmall from '../../assets/images/slide-small.png';
import photoSmall1 from '../../assets/images/slide-small2.png';
import photoSmall2 from '../../assets/images/slide-small3.png';
import photoSmall3 from '../../assets/images/slide-small4.png';
import photoSmall4 from '../../assets/images/slide-small5.png';
import photoSmall5 from '../../assets/images/slide-small6.png';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/pagination';

export const Slider: FC = () => {
	const [startImg, setStartImg] = useState(1);

	const nextImg = () => {
		setStartImg((prev: number) => prev + 1);
	};

	const prevImg = () => {
		setStartImg((prev: number) => prev - 1);
	};
	return (
		<Swiper
			slidesPerView={6}
			spaceBetween={1}
			// pagination={{
			// 	clickable: true,
			// }}
			breakpoints={{
				320: {
					slidesPerView: 1,
				},
				440: {
					slidesPerView: 2,
				},
				640: {
					slidesPerView: 3,
					// spaceBetween: 40,
				},
				948: {
					slidesPerView: 4,
					// spaceBetween: 40,
				},
				1112: {
					slidesPerView: 5,
					// spaceBetween: 40,
				},
				1338: {
					slidesPerView: 6,
					// spaceBetween: 10,
				},
			}}
			// modules={[Pagination]}
			className="mySwiper"
		>
			<SliderPreviousButton prevImg={prevImg} startImg={startImg} />

			<SwiperSlide>
				<img src={photoSmall} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<img src={photoSmall1} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<img src={photoSmall2} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<img src={photoSmall3} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<img src={photoSmall4} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<img src={photoSmall5} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<img src={photoSmall3} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<img src={photoSmall1} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<img src={photoSmall4} alt="" />
			</SwiperSlide>
			<SlideNextButton
				nextImg={nextImg}
				startImg={startImg}
				numberImg={[1, 2, 3, 4, 5, 6, 7, 8, 9].length}
			/>
		</Swiper>
	);
};
