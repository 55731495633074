import React, { FC } from 'react';
import { Slider } from '../Slider/Slider';

export const SmallSlider: FC = () => {
	return (
		<div className="small-slider-wrap">
			<Slider />
		</div>
	);
};
