/* eslint-disable import/no-unresolved */
import { FC } from 'react';
import { useSwiper } from 'swiper/react';
import { ISliderPreviousButton } from '../../types/TSlider';

const SliderPreviousButton: FC<ISliderPreviousButton> = ({ prevImg, startImg }) => {
	const swiper = useSwiper();

	return (
		<button
			// disabled={startImg === 1}
			onClick={() => {
				swiper.slidePrev();
				prevImg();
			}}
			type="button"
			className="slider-arrow slider-arrow--prev"
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="18"
				height="19"
				viewBox="0 0 18 19"
				fill="none"
			>
				<path
					d="M12 15.5L5.25 9.5L12 3.5"
					stroke="#0A49A5"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</button>
	);
};

export default SliderPreviousButton;
