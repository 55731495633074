import { FC } from 'react';
import photo from '../../assets/images/Partnership.png';

export const Partnership: FC = () => {
	return (
		<section id="Partnership" className="about-company">
			<img src={photo} alt="" />
			<div className="about-company__wrapper">
				<div className="text">Partnership</div>
				<div className="about-company__subtext">
					If you want to obtain a partner status for your YouTube channels, please follow one of the
					following links:
				</div>
				<ul className="about-company__list">
					<li className="about-company__item">
						<a
							href="https://docs.google.com/forms/d/e/1FAIpQLSfPldnnl-czU3tKPJAClt4_JyS256uR_dZ4UGQzhDYsxLAXJQ/viewform?usp=sf_link"
							target="_blank"
							rel="noreferrer"
						>
							Application for obtaining partner status for legal entities
						</a>
					</li>
					<li className="about-company__item">
						<a
							href="https://docs.google.com/forms/d/e/1FAIpQLSeeBQR253CQdJ_DGD_zuwAh_IIANmF98anwUgeALMjc7TVQBg/viewform?usp=sf_link"
							target="_blank"
							rel="noreferrer"
						>
							Application for obtaining partner status for individual entrepreneurs
						</a>
					</li>
					<li className="about-company__item">
						<a
							href="https://docs.google.com/forms/d/e/1FAIpQLScf-5z2I_U_UnSA9BI0_qHx1Hq0ZAnQ5zj8u8EtFhQ5NsKeHw/viewform?usp=sf_link"
							target="_blank"
							rel="noreferrer"
						>
							Application for obtaining partner status for individuals
						</a>
					</li>
				</ul>
			</div>
		</section>
	);
};
