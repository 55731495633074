import { FC, useEffect } from 'react';
import './Terms.scss';

export const Terms: FC = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			// behavior: 'smooth',
		});
	}, []);

	return (
		<div className="terms">
			<p className="terms__subtitle padding-bottom">
				Welcome to Ello Messenger, operated by Ello Messenger Corporation, located at 254 Chapman
				Rd, Ste 209, Newark DE 19702. By using the Mobile Application on iOS and Android, the
				Website located at{' '}
				<a href="https://ellomessenger.com" target="_blank" rel="noreferrer">
					https://ellomessenger.com
				</a>{' '}
				and
				<a href="https://ello.team" target="_blank" rel="noreferrer">
					{' '}
					https://ello.team
				</a>{' '}
				(collectively “Ello”), you agree to be bound by these Terms of Service (this “Terms of
				Service” or “Agreement”), whether or not you register as a User of the Messenger (`User`).
				If you wish to become a User and/or make use of the service (the “Service”), please read
				this Agreement. If you object to anything in this Agreement or the Privacy Policy, do not
				use Ello and the Service.
			</p>
			<p className="terms__subtitle padding-bottom">
				This Agreement is subject to change at any time, effective upon posting on Ello. Your
				continued use of Ello and posting of revised terms of any section of the Agreement will
				constitute your express and binding acceptance of and consent to the revised Agreement.
			</p>

			<p className="terms__subtitle">
				PLEASE READ THIS AGREEMENT CAREFULLY AS IT CONTAINS IMPORTANT INFORMATION REGARDING YOUR
				LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS, INCLUDING VARIOUS LIMITATIONS AND EXCLUSIONS, AND A
				DISPUTE RESOLUTION CLAUSE THAT GOVERNS HOW DISPUTES WILL BE RESOLVED.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">Electronic Agreement</h3>

			<p className="terms__subtitle">
				This Agreement is an electronic contract that sets out the legally binding terms of your use
				of Ello and the Service. This Agreement may be modified from time to time, such
				modifications to be effective upon posting on{' '}
				<a href="https://ellomessenger.com" target="_blank" rel="noreferrer">
					https://ellomessenger.com
				</a>{' '}
				and
				<a href="https://ello.team" target="_blank" rel="noreferrer">
					{' '}
					https://ello.team
				</a>{' '}
				. By accessing Ello and the Service or becoming a User, you accept this Agreement and agree
				to the terms, conditions, and notices contained or referenced herein.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">1. Access and Retention</h3>

			<p className="terms__subtitle">
				In order to access and retain this electronic Agreement, you must have access to the
				Internet, either directly or through devices that access web-based content. In addition, you
				must use all equipment necessary to make such a connection to the World Wide Web, including
				a mobile device, computer, and/or other internet devices. You may print a copy of this
				document for your records. An electronic copy of this Agreement is available on Ello.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">2. Commercial Use of Service.</h3>

			<p className="terms__subtitle">
				If you are accessing Ello and the Service on behalf of a company, entity, or organization
				(collectively, a “Subscribing Entity”), you represent and warrant that: <br /> a. You are an
				authorized representative of the Subscribing Entity, and you have the authority to bind the
				Subscribing Entity to this Terms of Service; <br /> b. You have read and understand these
				Terms of Service; and <br /> c. You agree to this Terms of Service on behalf of the
				Subscribing Entity.
				<br />
				<br /> Illegal and/or unauthorized uses of Ello and the Service include but are not limited
				to, browsing or downloading illegal content, collecting usernames and/or email addresses of
				members by electronic or other means for the purpose of sending unsolicited emails,
				unauthorized framing of or linking, sharing or disclosing your username or password to any
				third party or permitting any third party to access your account, attempting to impersonate
				another user or person, use of Ello and the Service in any fraudulent or misleading manner,
				any automated use of the system, such as scraping Ello and the Service, automated scripts,
				spiders, robots, crawlers, data mining tools or the like, interfering with, disrupting, or
				creating an undue burden on Ello and the Service or the networks or services connected to
				them, and using Ello and the Service in a manner inconsistent with any and all applicable
				laws and regulations. Illegal and/or unauthorized use of Ello and the Service may be
				investigated, and appropriate legal action may be taken, including without limitation,
				civil, criminal, and injunctive redress.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">3. Account Security </h3>

			<p className="terms__subtitle">
				You are responsible for maintaining the confidentiality of the username and password that
				you designate during the registration process, and you are fully responsible for all
				activities that occur under your username and password.
			</p>

			<p className="terms__subtitle padding-bottom">You agree to:</p>

			<p className="terms__subtitle">
				(a) immediately notify Ello of any unauthorized use of your username or password or any
				other breach of security, and (b) ensure that you exit from your account at the end of each
				session. Ello will not be liable for any loss or damage arising from your failure to comply
				with this provision. You should use particular caution when accessing your account from
				public or shared internet devices so that others are not able to view or record your
				password or other personal information. If you share your internet devices with others, you
				may wish to consider disabling your auto-login feature if you have it linked to your
				account.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">4. Your use of Ello</h3>

			<p className="terms__subtitle">
				a. You may not browse or download illegal content;
				<br />
				b. You must not copy or capture, or attempt to copy or capture, any content (the “Content”)
				from Ello or any part of Ello unless given express permission;
				<br />
				c. You must not copy, republish, adapt, make available, or otherwise communicate to the
				public, display, perform, transfer, share, distribute, or otherwise use or exploit any
				Content on or from Ello, except (i) where such Content is created by you (such content,
				“Your Content”), or (ii) as permitted under these Terms of Service, and within the
				parameters set by the person or entity that uploaded the Content (the “Uploader”) (for
				example, under the terms of Creative Commons licenses selected by the Uploader);
				<br />
				d. You must not use any Content (other than our Content) in any way that is designed to
				create a separate content service or that replicates any part of Ello’s offering;
				<br />
				e. You must not employ scraping or similar techniques to aggregate, repurpose, republish, or
				otherwise make use of any Content;
				<br />
				<br />
				f. You must not employ the use of bots, botnets, scripts, apps, plugins, extensions, or
				other automated means to register accounts, log in, post comments, or otherwise act on your
				behalf, particularly where such activity occurs in multiple or repetitive fashion. You must
				not offer or promote the availability of any such techniques or services to any other users
				of Ello;
				<br />
				g. You must not alter or remove, or attempt to alter or remove, any trademark, copyright, or
				other proprietary or legal notices contained in, or appearing on, or any Content appearing
				on Ello (other than Your Content);
				<br />
				h. You must not, and must not permit any third party to, copy or adapt the object code of
				Ello, or reverse engineer, reverse assemble, decompile, modify or attempt to discover any
				source or object code of any part of Ello, or circumvent or attempt to circumvent or copy
				any copy protection mechanism or access any rights management information pertaining to
				Content other than Your Content;
				<br />
				i. You must not use Ello to upload, post, store, transmit, display, copy, distribute,
				promote, make available, or otherwise communicate to the public:
				<br />
				<br />
				(a) any Content that is offensive, abusive, libelous, defamatory, obscene, racist, explicit,
				ethnically, or culturally offensive, indecent, that promotes violence, terrorism, or illegal
				acts, incites hatred on grounds of race, gender, religion, or sexual orientation, or is
				otherwise objectionable in Ello’s sole and reasonable discretion;
				<br />
				(b) any information, Content, or other material that violates, plagiarizes, misappropriates,
				or infringes the rights of third parties including, without limitation, copyright, trademark
				rights, rights of privacy or publicity, confidential information, or any other right; or
				<br />
				(c) any Content that violates, breaches, or is contrary to any law, rule, regulation, court
				order or is otherwise illegal or unlawful in Ello’s sole and reasonable opinion;
				<br />
				(d) any material of any kind that contains any virus, Trojan horse, spyware, adware,
				malware, bot, time bomb, worm, or other harmful or malicious component, which will or might
				overburden, impair, or disrupt Ello or servers or networks forming part of, or connected to,
				Ello, or which does or might restrict or inhibit any other user&apos;s use and enjoyment of
				Ello; or
				<br />
				(e) any unsolicited or unauthorized advertising, promotional messages, spam, or any other
				form of solicitation;
				<br />
				(f) you must not commit or engage in, or encourage, induce, solicit, or promote, any conduct
				that would constitute a criminal offense, give rise to civil liability, or otherwise violate
				any law or regulation.
				<br />
				<br />
				k. You must not rent, sell, or lease access to Ello, or any Content on Ello, although this
				will not prevent you from including links from Your Content to any legitimate online
				resource from where any item of Your Content may be purchased;
				<br />
				<br />
				l. You must not deliberately impersonate any person or entity or otherwise misrepresent your
				affiliation with a person or entity, for example, by registering an account in the name of
				another person or company, sending messages, or making comments using the name of another
				person.
				<br />
				<br />
				m. You must not stalk, exploit, threaten, abuse, or otherwise harass another User. If we
				feel that your behavior towards any of our Users is at any time threatening or offensive, we
				reserve the right to immediately terminate your account and you will not be entitled to any
				refund of unused subscription fees.
				<br />
				<br />
				n. You must not sell or transfer, or offer to sell or transfer, any account to any third
				party without the prior written approval of Ello;
				<br />
				<br />
				o. You must not collect or attempt to collect personal data, or any other kind of
				information about other users, including without limitation, through spidering or any form
				of scraping;
				<br />
				<br />
				p. You must not violate, circumvent, or attempt to violate or circumvent any data security
				measures employed by Ello or any Uploader; access or attempt to access data or materials
				which are not intended for your use; log into, or attempt to log into, a server or account
				which you are not authorized to access; attempt to scan or test the vulnerability of Ello’s
				servers, systems or networks or attempt to breach Ello’s data security or authentication
				procedures; attempt to interfere with Ello or the Services by any means including, without
				limitation, hacking Ello’s servers or systems, submitting a virus, overloading, mail-bombing
				or crashing. Without limitation to any other rights or remedies of Ello under these Terms of
				Service, Ello reserves the right to investigate any situation that appears to involve any of
				the above and may report such matters to, and cooperate with, appropriate law enforcement
				authorities in prosecuting any users who have participated in any such violations.
				<br />
				<br />
				You agree to comply with the above conditions and acknowledge and agree that Ello has the
				right, in its sole discretion, to terminate your account or take such other action as we see
				fit if you breach any of the above conditions or any of the other terms of these Terms of
				Service. This may include taking court action and/or reporting offending Users to the
				relevant authorities.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">
				5. Representations and Warranties
			</h3>
			<p className="terms__subtitle">
				You hereby represent and warrant to Ello as follows:
				<br />
				<br />
				a. Your Content, and each and every part thereof, is an original work by you, or you have
				obtained all rights, licenses, consents, and permissions necessary in order to use, and (if
				and where relevant) to authorize Ello to use, Your Content pursuant to these Terms of
				Service, including, without limitation, the right to upload, reproduce, store, transmit,
				distribute, share, publicly display, publicly perform, make available and otherwise
				communicate to the public Your Content, and each and every part thereof, on, through or via
				Ello, any and all Services.
				<br />
				<br />
				b. Your Content and the availability thereof on Ello does not and will not infringe or
				violate the rights of any third party, including, without limitation, any intellectual
				property rights, performers&apos; rights, rights of privacy or publicity, or rights in
				confidential information.
				<br />
				<br />
				c. You have obtained any and all necessary consents, permissions, and/or releases from any
				and all persons appearing in Your Content in order to include their name, voice,
				performance, or likeness in Your Content and to publish the same on Ello and via any
				third-party services.
				<br />
				<br />
				d. Your Content, including any comments that you may post on Ello, is not and will not be
				unlawful, offensive, abusive, libelous, defamatory, obscene, racist, sexually explicit,
				ethnically, or culturally offensive, indecent, will not promote violence, terrorism, or
				illegal acts, or incite hatred on grounds of race, gender, religion or sexual orientation.
				<br />
				<br />
				e. Your Content does not and will not create any liability on the part of Ello, its
				subsidiaries, affiliates, successors, and assigns, and their respective employees, agents,
				directors, officers, and/or shareholders.
				<br />
				<br />
				Ello reserves the right to remove Your Content, suspend or terminate your access, and/or
				pursue all legal remedies if we believe that any of Your Content breaches any of the
				foregoing representations or warranties, or otherwise infringes another person&apos;s rights
				or violates any law, rule, or regulation.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">6. Term</h3>
			<p className="terms__subtitle">
				This Agreement will remain in full force and effect while you use Ello and/or Service. You
				may terminate your account and/or subscription at any time. If you resign or cancel your
				account and/or subscription to Ello, to help analyze and improve the Service, you may be
				asked to provide a reason for your cancellation. Ello may terminate your membership and/or
				subscription for any reason by sending a notice to you at the email address you provide in
				your registration for the account. If Ello terminates your account in the Service because
				you have breached this Agreement, you will not be entitled to any refund of unused
				subscription fees. All decisions regarding the termination of accounts shall be made at the
				sole discretion of Ello. Ello is not required to provide you notice prior to terminating
				your account and/or subscription. Ello is not required, and may be prohibited, from
				disclosing a reason for the termination of your account. Even after your account or
				subscription is terminated, this Agreement will remain in effect. All terms that by their
				nature may survive termination of this Agreement shall be deemed to survive such termination
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">
				7. Modifications of the Services
			</h3>
			<p className="terms__subtitle">
				Ello reserves the right at any time to modify or discontinue, temporarily or permanently,
				the Service (or any part thereof) with or without notice. You agree that Ello shall not be
				liable to you or to any third party for any modification, suspension, or discontinuance of
				the Service.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">8. Blocking of IP Addresses</h3>
			<p className="terms__subtitle">
				In order to protect the integrity of the Services, Ello reserves the right at any time in
				its sole discretion to block Users from certain IP addresses from accessing it.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">9. Content</h3>
			<p className="terms__subtitle">
				a. Proprietary Rights
				<br />
				<br />
				Ello retains all proprietary rights in the Services. Ello contains the copyrighted
				materials, trademarks, and other proprietary information of Ello and its licensors. Except
				where we have given you express written permission, you may not copy, modify, publish,
				transmit, distribute, perform, display, or sell any such proprietary information. All
				Content on Ello is proprietary. Except where otherwise specified in this Agreement, all
				Content is the copyrighted material of Ello and for Ello’s Users&apos; use only.
				Distribution of Content to others is strictly prohibited. You agree that Ello would be
				irreparably harmed by any violation or threatened violation of this section and that,
				therefore, Ello shall be entitled to an injunction prohibiting you from any violation or
				threatened violation of this section, without posting bond, in addition to any other right
				or remedy it may have.
				<br />
				<br />
				We may provide links to third-party internet resources, and some of the content appearing on
				Ello may be supplied by third parties. Ello has no responsibility for these third-party
				internet resources nor for their Content, which is subject to and governed by the Terms of
				Service and/or privacy policies, if any, of the applicable third-party Content providers.
				<br />
				<br />
				b. Ownership of Your Content and Licenses
				<br />
				<br />
				You agree that any content you upload to Ello and/or the Service (“Your Content”) is your
				property.
				<br />
				<br />
				You agree to grant Ello a worldwide, royalty-free, and non-exclusive license(s) to use,
				distribute, reproduce, and publicly display such content, including Your Content uploaded to
				Business and/or Personal accounts.
				<br />
				<br />
				Any Content other than Your Content is the property of the relevant Uploader and is or may
				be subject to copyright, trademark rights, or other intellectual property or proprietary
				rights. Such Content may not be downloaded, reproduced, distributed, transmitted,
				re-uploaded, republished, displayed, sold, licensed, made available or otherwise
				communicated to the public or exploited for any purposes except via the features of Ello
				from time to time and within the parameters set by the Uploader on the Service or with the
				express written consent of the Uploader. Where you repost another user&apos;s Content, or
				include another user&apos;s Content in a set, you acquire no ownership rights whatsoever in
				that Content. Subject to the rights expressly granted in this section, all rights in Content
				are reserved to the relevant Uploader.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">
				10. Restrictions on the Use of Materials
			</h3>
			<p className="terms__subtitle">
				You acknowledge that Ello contains images, text, and other content (collectively,
				&quot;Intellectual Property&quot;) that is protected by copyrights, patents, trademarks,
				trade secrets, and/or other proprietary rights and that these rights are valid and protected
				in all forms, media, and technologies existing now or hereafter developed. All Intellectual
				Property is copyrighted under the United States and International copyright laws (and, if
				applicable, similar foreign laws), and Ello owns a copyright in the selection, coordination,
				arrangement, and enhancement of such Intellectual Property. All trademarks appearing on Ello
				are trademarks of their respective owners. You may not modify, publish, transmit,
				distribute, perform, participate in the transfer or sale, create derivative works of, or in
				any way exploit, any of the Intellectual Property, in whole or in part. When Intellectual
				Property is downloaded to your computer, you do not obtain any ownership interest in such
				Intellectual Property. Modification of the Intellectual Property or use of the Intellectual
				Property for any other purpose, including, but not limited to, use of any Intellectual
				Property in printed form or on any other website or networked computer environment is
				strictly prohibited unless you receive our prior written consent.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">11. Copyright Policy</h3>
			<p className="terms__subtitle">
				Ello prohibits the submission or posting of any information that infringes or violates the
				copyright rights and/or other intellectual property rights (including rights of privacy and
				publicity) of any person or entity.
				<br />
				<br />
				Pursuant to Title 17, United States Code, Section 512(c)(2) or for any other claim of
				copyright infringement, you hereby agree that notifications of claimed copyright
				infringement be sent to{' '}
				<a href=" info@ello.team" target="_blank" rel="noreferrer">
					info@ello.team
				</a>{' '}
				or using the Ello Report Form.
				<br />
				<br />
				If you believe that your intellectual property right (or such a right that you are
				responsible for enforcing) is infringed by any content on the Site, please write to Ello at
				the email shown above, giving a written statement that contains:
				<br />
				<br />
				a. Identification of the copyrighted work and/or intellectual property right claimed to have
				been infringed;
				<br />
				<br />
				b. Identification of the allegedly infringing material on the Site that is requested to be
				removed;
				<br />
				<br />
				c. Your name, address, and daytime telephone number, and an e-mail address if available;
				<br />
				<br />
				d. A statement that you have a good faith belief that the use of the copyrighted work and/or
				exercise of the intellectual property rights is not authorized by the owner, its agent, or
				the law;
				<br />
				<br />
				e. A statement that the information in the notification is accurate, and, under penalty of
				perjury, that the signatory is authorized to act on behalf of the owner of the right that is
				allegedly infringed; and
				<br />
				<br />
				f. The signature of the intellectual property right owner or someone authorized on the
				owner&apos;s behalf to assert infringement of the right.
				<br />
				<br />
				Ello will process any notice of alleged infringement that it receives and will take
				appropriate action as required by the Digital Millennium Copyright Act (DMCA) 17 U.S.C.
				512(c)(3) or other applicable copyright law. U.S. law provides significant penalties for
				submitting such a statement falsely. Under appropriate circumstances, persons who repeatedly
				submit infringing or unlawful material will be prohibited from posting further submissions.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">12. Liability for Content</h3>
			<p className="terms__subtitle">
				You hereby acknowledge and agree that Ello (i) stores Content and other information at the
				direction, request, and authorization of its Users, (ii) acts merely as a passive conduit
				and/or host for the uploading, storage, and distribution of such Content, and (iii) plays no
				active role and gives no assistance in the presentation or use of the Content. You are
				solely responsible for all of the Content that you upload, post, or distribute to, on or
				through Ello, and to the extent permissible by law, Ello excludes all liability with respect
				to all Content (including Your Content) and the activities of its Users with respect
				thereto.
				<br />
				<br />
				You hereby acknowledge and agree that Ello cannot and does not review the Content created or
				uploaded by its users, and neither Ello nor its subsidiaries, affiliates, successors,
				assigns, employees, agents, directors, officers and shareholders has any obligation and does
				not undertake or assume any duty, to monitor the Content that is inappropriate, that does or
				might infringe any third party rights, or has otherwise been uploaded in breach of these
				Terms of Service or applicable law.
				<br />
				<br />
				Ello and its subsidiaries, affiliates, successors, assigns, employees, agents, directors,
				officers, and shareholders hereby exclude, to the fullest extent permitted by law, any and
				all liability that may arise from any Content uploaded to Ello by users, including, but not
				limited to, any claims for infringement of intellectual property rights, rights of privacy
				or publicity rights, any claims relating to the publication of defamatory, erotic, obscene
				or offensive material, or any claims relating to the completeness, accuracy, currency or
				reliability of any information provided by users of Ello. By using Ello, you irrevocably
				waive the right to assert any claim concerning any of the foregoing against Ello or any of
				its subsidiaries, affiliates, successors, assigns, employees, agents, directors, officers,
				or shareholders.
			</p>
			<h3 className="terms__title-h3 padding-top padding-bottom">13. Repeat Infringers</h3>
			<p className="terms__subtitle">
				Ello will suspend or terminate your access to its Services if Ello determines, in its sole
				and reasonable discretion, that you have repeatedly breached these Terms of Service.
				<br />
				<br />
				If we receive a valid notification from a third party in accordance with our reporting
				processes or applicable law that any of Your Content infringes the copyright or other rights
				of such third party, or if we believe that your behavior is inappropriate and violates our
				Terms of Service, we will send you a written warning to this effect. Any user that receives
				more than two of these warnings is liable to have their access to the Websites terminated
				forthwith.
				<br />
				<br />
				We will also suspend or terminate your account without warning if ordered to do so by a
				court, and/or in other appropriate circumstances, as determined by Ello at its sole
				discretion.
				<br />
				<br />
				Please note that we do not offer refunds to Users whose accounts are terminated as a result
				of repeated infringement of these Terms of Service.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">14. Limitation of Liability</h3>
			<p className="terms__subtitle">
				In no event shall Ello be liable for any damages whatsoever, whether direct, indirect,
				general, special, compensatory, consequential, and/or incidental, arising out of or relating
				to the Websites or Service, or use thereof. Nothing contained in Ello or any written or oral
				communications from Ello or its employees or agents shall be construed to make any promise,
				covenant, or guarantee, all of which are explicitly disclaimed hereby, contrary to the
				statements and disclaimers contained in this paragraph.
				<br />
				<br />
				The content and functionality of Ello and its Services provided by employees of Ello are
				offered &quot;as is&quot; without warranty of any kind, either express or implied,
				including, without limitation, implied warranties of merchantability, fitness for a
				particular purpose, title, and non-infringement. Ello makes no warranties, express or
				implied, as to the ownership, accuracy, completeness, or adequacy of Ello’s content or that
				the functionality of Ello will be uninterrupted error-free, or free from virus or
				third-party attack. You hereby acknowledge that your use of Ello and the Services is at your
				sole risk. UNDER NO CIRCUMSTANCES SHALL Ello, ITS OFFICERS, OWNERS, SHAREHOLDERS, EMPLOYEES
				OR AGENTS AND THEIR RESPECTIVE HEIRS, SUCCESSORS AND ASSIGNS BE LIABLE FOR ANY DAMAGES,
				INCLUDING DIRECT, INCIDENTAL, PUNITIVE, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES THAT
				DIRECTLY OR INDIRECTLY RESULT FROM USE OF, OR INABILITY TO USE, THE WEBSITES OR SERVICE OR
				THE INFORMATION CONTAINED THEREIN, INCLUDING WITHOUT LIMITATION FOR VIRUSES ALLEGED TO HAVE
				BEEN OBTAINED FROM THE WEBSITES, EVEN IF ELLO HAS BEEN NOTIFIED OF THE POSSIBILITY OF SUCH
				DAMAGES OR LOSSES AND REGARDLESS OF THE THEORY OF LIABILITY.
				<br />
				<br />
				TO THE GREATEST EXTENT PERMITTED BY LAW, YOU AGREE THAT IN NO EVENT WILL ELLO HAVE ANY
				LIABILITY, CONTINGENT OR OTHERWISE, FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR
				EXEMPLARY DAMAGES IN ANY WAY ARISING OUT OF OR RELATING TO THE AGREEMENT, ELLO OR THE
				SERVICE, INCLUDING, BUT NOT LIMITED TO LOST PROFITS; LOST DATA; LOSS OF GOODWILL; COPYRIGHT,
				TRADEMARK, OR OTHER INTELLECTUAL PROPERTY INFRINGEMENT; WORK STOPPAGE; EQUIPMENT FAILURE OR
				MALFUNCTION; ILLEGAL, IMMORAL OR FRAUDULENT ACTIVITY; PERSONAL INJURY; PROPERTY DAMAGE; OR
				ANY OTHER DAMAGES OR LOSSES, EVEN IF [Site name] HAS BEEN ADVISED OF THE POSSIBILITY
				THEREOF, AND REGARDLESS OF THE LEGAL OR EQUITABLE THEORY (CONTRACT, TORT, STATUTE, INDEMNITY
				OR OTHERWISE) UPON WHICH SUCH LIABILITY IS BASED.
				<br />
				<br />
				If any of the foregoing limitations are deemed to be unenforceable, to the greatest extent
				permitted by law, you agree that the entire aggregate liability of Ello and sole remedy
				available to any User in any case in any way arising out of or relating to the Agreement,
				Ello or the Service shall be limited to monetary damages that in the aggregate may not
				exceed the greater of $100.00 or the sum of any amount paid by the User to Ello during the
				six months prior to notice to Ello of the dispute for which the remedy is sought.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">15. Indemnity by You</h3>
			<p className="terms__subtitle">
				You agree to indemnify and hold Ello, its owners, shareholders, subsidiaries, affiliates,
				officers, agents, and other partners and employees, harmless from any loss, liability,
				claim, or demand, including reasonable attorneys&apos; fees, arising out of, or related to:
				<br />
				<br />
				a. Your use of the Service and/or Ello in violation of this Agreement and/or arising from a
				breach of this Agreement including without limitation your representations and warranties
				set forth above;
				<br />
				<br />
				b. Any third party claim of infringement of copyright or other intellectual property rights
				or invasion of privacy arising from the hosting of Your Content on Ello, and/or your making
				available thereof to other Users, and/or the actual use of Your Content by other Users or
				related services in accordance with these Terms of Service and the parameters set by you
				concerning the distribution and sharing of Your Content;
				<br />
				<br />
				c. Any activity related to your account, either by you or by any other person accessing your
				account with or without your consent unless such activity was caused by the act of Ello.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">16. Attorney Fees</h3>
			<p className="terms__subtitle">
				In the event that Ello is successful in whole or in part in any action or proceeding related
				to or arising from this Agreement, you shall be responsible for Ello’s attorneys&apos; fees
				and costs.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">
				17. Parental or Guardian Permission
			</h3>
			<p className="terms__subtitle">
				Some of the Content on Ello may not be appropriate for children. CHILDREN UNDER THE AGE OF
				17 ARE NOT PERMITTED TO USE ELLO UNLESS A SUPERVISING PARENT OR GUARDIAN IS PRESENT.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">18. Privacy</h3>
			<p className="terms__subtitle">
				Use of Ello and/or the Service is also governed by our Privacy Policy, located at{' '}
				<a href="https://ellomessenger.com/privacy-policy" target="_blank" rel="noreferrer">
					https://ellomessenger.com/privacy-policy
				</a>
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">
				19. Jurisdiction and Choice of Law and Dispute Resolution
			</h3>
			<p className="terms__subtitle">
				If there is any dispute arising out of Ello and/or the Service, by using Ello and/or
				Service, you expressly agree that any such dispute shall be governed by the laws of the
				State of Delaware, without regard to its conflict of law provisions, and you expressly agree
				and consent to the exclusive jurisdiction and venue of the state and federal courts of the
				State of Delaware, for the resolution of any such dispute. Acceptance of the terms and
				conditions of this Agreement constitutes your consent to be sued in such courts and to
				accept service of process outside the State of Delaware with the same force and effect as if
				such service had been made within the State of California. You hereby agree to accept
				service of process for any action hereunder by certified mail return receipt requested which
				service shall have the same force and effect as though service had been affected by personal
				service in the applicable jurisdiction. If any part of these terms is unlawful, void, or
				unenforceable, that part will be deemed severable and will not affect the validity and
				enforceability of the remaining provisions.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">
				20. Arbitration Provision/No Class Action
			</h3>
			<p className="terms__subtitle">
				Except where prohibited by law, as a condition of using Ello and/or Service, you agree that
				any disputes, claims, and causes of action (collectively, &quot;Claim&quot;) arising out of
				or connected with Ello and/or Service, shall be resolved individually, without resort to any
				form of class action, exclusively by binding arbitration under the rules of the American
				Arbitration Association for full and final settlement of such Claim, and judgment on the
				award rendered in the arbitration may be entered in any court having jurisdiction thereof.
				Such arbitration shall be held in accordance with the Rules for Expedited Procedures under
				the Commercial Arbitration Rules of the American Arbitration Association or other mutually
				agreeable organization, before a single arbitrator (with knowledge and expertise of
				copyright law if the claim is all or partially for copyright infringement), selected by
				agreement of both parties or by an independent mediator (with knowledge and expertise of
				copyright law if the claim is all or partially for copyright infringement) if the parties
				are unable to agree. The parties shall split the arbitration and/or mediator costs. An award
				rendered by the arbitrator(s) may be entered and confirmed by the courts of the State of
				Delaware, New Castle County. The parties agree that any post-arbitration action seeking to
				enforce an arbitration award or action seeking equitable or injunctive relief shall be
				brought exclusively in the courts of the State of Delaware, County of New Castle.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">
				21. No Third-Party Beneficiaries
			</h3>
			<p className="terms__subtitle">
				You agree that, except as otherwise expressly provided in this Agreement, there shall be no
				third-party beneficiaries to this Agreement.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">
				22. Availability Outside the U.S.
			</h3>
			<p className="terms__subtitle">
				If you access Ello from outside of the United States, you do so at your own risk and are
				responsible for compliance with the laws of your jurisdiction. If you access Ello from
				outside of the United States, you acknowledge that any personal information you provide will
				be processed in the United States and other geographies as selected by us in our sole
				discretion, and you hereby consent to the collection and processing of your personal
				information in a manner consistent with this Agreement and the Privacy Policy.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">23. Entire Agreement</h3>
			<p className="terms__subtitle">
				This Agreement contains the entire agreement between you and Ello regarding the use of the
				Websites and/or the Service.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">24. Severability; Waiver</h3>
			<p className="terms__subtitle">
				If any provision of this Agreement is found to be invalid by any court having competent
				jurisdiction, the invalidity of such provision shall not affect the validity of the
				remaining provisions of this Agreement, which shall remain in full force and effect. No
				waiver of any term of this Agreement shall be deemed a further or continuing waiver of such
				term or any other term. In addition, Ello’s failure to enforce any term of this Agreement
				shall not be deemed as a waiver of such term or otherwise affect Ello’s ability to enforce
				such term at any point in the future.
			</p>

			<h3 className="terms__title-h3 padding-top padding-bottom">25. Headings</h3>
			<p className="terms__subtitle padding-bottom">
				The section headings contained in this Agreement are for reference purposes only and shall
				not in any way affect the meaning or interpretation of this Agreement. Please contact us
				with any questions regarding this Agreement.
			</p>

			<p className="terms__subtitle">
				I HAVE READ THIS AGREEMENT AND AGREE TO ALL OF THE PROVISIONS CONTAINED ABOVE.
			</p>
		</div>
	);
};
