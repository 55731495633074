import { FC } from 'react';
import photo from '../../assets/images/ello.png';

export const AboutCompany: FC = () => {
	return (
		<section id="ello" className="about-company">
			<img src={photo} alt="" />
			<div className="about-company__wrapper">
				<div className="text">About the company</div>
				<div className="about-company__subtext">
					<span>ELLO</span> is one of the largest music channels on YouTube, specializing in the
					distribution of music video clips.
				</div>
			</div>
		</section>
	);
};
