import { FC } from 'react';

export const NavigationRight: FC<{ setPage: (value: string) => void }> = ({ setPage }) => {
	return (
		<nav className="header__navigation navigation">
			<ul className="navigation__list">
				<li onClick={() => setPage('')} className="navigation__item">
					<a href="#start" className="navigation__link">
						Home
					</a>
				</li>
				<li onClick={() => setPage('')} className="navigation__item">
					<a href="#ello" className="navigation__link">
						About the company
					</a>
				</li>
				<li onClick={() => setPage('')} className="navigation__item">
					<a href="#Placing" className="navigation__link">
						Placing your video
					</a>
				</li>
				<li className="navigation__item">
					<a
						href="https://youtube.com/@ELLO?si=5bQ_O5eUSVDYe05U"
						className="navigation__link"
						target="_blank"
						rel="noreferrer"
					>
						ELLO Video Production
					</a>
				</li>
				<li onClick={() => setPage('')} className="navigation__item">
					<a href="#Partnership" className="navigation__link">
						Partnership
					</a>
				</li>
				<li className="navigation__item">
					<a
						href="https://docs.google.com/forms/d/e/1FAIpQLSfdmbGE8zjgGaZy-vY_KacDU9hsRx2wjR1LiJ5zp3typtwtHQ/viewform?usp=sf_link"
						className="navigation__link"
						target="_blank"
						rel="noreferrer"
					>
						Contact
					</a>
				</li>
			</ul>
		</nav>
	);
};
