import { FC } from 'react';
import photo from '../../assets/images/Placement.png';

export const Placement: FC = () => {
	return (
		<section id="Placing" className="about-company reverce">
			<div className="about-company__wrapper">
				<div className="text">Placement of your video</div>
				<div className="about-company__subtext">
					If you want to post a video on ELLO without obtaining a partner status, you must fill out
					the contract form by following one of the following links:
				</div>
				<ul className="about-company__list">
					<li className="about-company__item">
						<a
							href="https://docs.google.com/forms/d/e/1FAIpQLSfdmbGE8zjgGaZy-vY_KacDU9hsRx2wjR1LiJ5zp3typtwtHQ/viewform?usp=sf_link"
							target="_blank"
							rel="noreferrer"
						>
							Form for legal entities to fill out
						</a>
					</li>
					<li className="about-company__item">
						<a
							href="https://docs.google.com/forms/d/e/1FAIpQLSeiwo8-pnzcz53guI29ShFZBTwp-XSzVzMDJpRSYfLmhDVl_Q/viewform?usp=sf_link"
							target="_blank"
							rel="noreferrer"
						>
							Form for individual entrepreneurs to fill out
						</a>
					</li>
					<li className="about-company__item">
						<a
							href="https://docs.google.com/forms/d/e/1FAIpQLSe7gnnHwsjJBJXK7FT53AJSTMb2kpw79JKqNlZ1l_N5aJECng/viewform?usp=sf_link"
							target="_blank"
							rel="noreferrer"
						>
							Form for individuals to fill out
						</a>
					</li>
				</ul>
			</div>
			<img src={photo} alt="" />
		</section>
	);
};
